<template>
  <b-card title="Venue" no-body>
    <!-- *******เพิ่ม -->
    <b-sidebar
      :visible="opensidebar"
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Admin Invitation</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="opensidebar = false"
          />
        </div>

        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(submitemail)"
            @reset.prevent="resetForm"
          >
            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group label="Email" label-for="email">
                <b-form-input
                  id="email"
                  v-model="emaildata"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="opensidebar = false"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <!-- *******แก้ไข -->
    <b-sidebar
      :visible="opensidebar"
      id="sidebar-right-update"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <template #default="{ hide }">
        <validation-observer ref="registerForm1">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm1"
          >
            <!-- email -->
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="register-email"
                  readonly
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- username -->
            <b-form-group label="ชื่อ" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  name="register-name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group label="Password" label-for="a-password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8|password"
              >
                <b-form-input
                  id="a-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group label="Confirm Password" label-for="ac-password">
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  id="ac-password"
                  v-model="passValue"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Confirm Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password เดิมมมมมมม-->
            <b-form-group label="Old Password" label-for="old-password">
              <validation-provider #default="{ errors }" name="Old Password">
                <b-form-input
                  id="old-password"
                  v-model="passOld"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Old Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button variant="primary" block type="submit">
              Update data
            </b-button>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>

    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">none</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <b-button variant="primary" v-b-toggle.sidebar-right>
            <span class="text-nowrap">Add User</span>
          </b-button>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <!-- Column: User -->
      <template #cell(logo)="data">
        <b-media vertical-align="center" @click="click(data.item.id)">
          <template #aside>
            <b-avatar size="32" :src="data.item.logo" />
          </template>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveUserRoleIcon(data.item.role)"
            size="18"
            class="mr-50"
            :class="`text-${resolveUserRoleVariant(data.item.role)}`"
          />
          <span class="align-text-top text-capitalize">{{
            data.item.role
          }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            @click="edit_data(data.item.id)"
            v-b-toggle.sidebar-right-update
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="deleteuser(data.item)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BSidebar,
  VBToggle,
  BTable,
  BAvatar,
  BBadge,
  BForm,
  BFormInvalidFeedback,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import api from "@/api";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import store from "@/store";
import countries from "@/@fake-db/data/other/countries";
import vSelect from "vue-select";
import Swal from "sweetalert2";

export default {
  components: {
    BSidebar,
    BCard,
    BTable,
    BAvatar,
    vSelect,
    BBadge,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  setup() {
    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      /*
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
        */
      const params = {
        data: userData.value,
      };
      var vm = this;
      api
        .post("/admin/inviteadmin", params)
        .then((response) => {
          if (response.data == "exist") {
            console.log(response.data);
            this.opensidebar = false;

            this.$bvToast.toast("Toast body content", {
              title: "User นี้มีอยู่ในระบบแล้ว",
              variant: "danger",
              solid: true,
            });
          } else {
            this.opensidebar = true;
          }
          // console.log(response.data);
        })
        .catch((error) => {
          // router.push('/pages/not-authorized');
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,
      countries,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },

  data() {
    return {
      opensidebar: false,
      emaildata: "",
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "id", label: "Id" },
        { key: "avatar", label: "Avatar", sortable: false },
        { key: "name", label: "Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "role", sortable: true },
        { key: "actions" },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      password: null,
      passOld: null,
      name: null,
      id: null,
      email: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.loaddata();
    this.password = null;
  },
  methods: {
    validationForm1() {
      this.$refs.registerForm1.validate().then((success) => {
        const params = {
          email: this.email,
          name: this.name,
          user_id: this.id,
          password: this.password,
          passOld: this.passOld,
        };
        api.post("/updateuser", params).then((response) => {
          console.log("response", response.data);
          if (response.data === "Incorrect Password") {
            Swal.fire({
              title: "Error!",
              text: " Incorrect Password",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (response.data === "update success") {
            Swal.fire({
              icon: "success",
              title: "success",
              showConfirmButton: false,
              timer: 1500,
            });
                this.loaddata();
                this.opensidebar = false;
          }
        });
      });
    },

    edit_data(id) {
      console.log("edit_data", id);
      const params = { id: id };
      api.post("/getuserbyid", params).then((response) => {
        console.log(response.data);

        for (let item of response.data.data) {
          (this.email = item.email),
            (this.name = item.name),
            (this.id = item.id);
          this.id = item.id;
          this.password = null;
           this.opensidebar = true;
        }
      });
    },

    deleteuser(user) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const params = {
            id: user.id,
            email: user.email,
          };
          api
            .post("/admin/deleteuser", params)
            .then((response) => {
              this.loaddata();
              // console.log(response.data);
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "User has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              router.push("/pages/not-authorized");
            });
        }
      });
    },
    submitemail() {
      const params = {
        email: this.emaildata,
      };
      var vm = this;
      api
        .post("/admin/inviteadmin", params)
        .then((response) => {
          if (response.data == "exist") {
            console.log(response.data);
            this.opensidebar = false;
            this.$bvToast.toast("User นี้มีอยู่ในระบบแล้ว", {
              title: "เกิดข้อผิดพลาด",
              variant: "danger",
              solid: true,
            });
          } else {
            this.opensidebar = false;
            this.$bvToast.toast("ส่ง Invite Email สำเร็จ", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          }
          console.log("response", response.data);
        })
        .catch((error) => {
          // router.push('/pages/not-authorized');
        });
    },

    resolveUserRoleVariant(role) {
      if (role === "subscriber") return "primary";
      if (role === "author") return "warning";
      if (role === "maintainer") return "success";
      if (role === "editor") return "info";
      if (role === "admin") return "danger";
      return "primary";
    },
    resolveUserRoleIcon(role) {
      if (role === "subscriber") return "UserIcon";
      if (role === "author") return "SettingsIcon";
      if (role === "maintainer") return "DatabaseIcon";
      if (role === "editor") return "Edit2Icon";
      if (role === "admin") return "ServerIcon";
      return "UserIcon";
    },

    click(id) {
      alert(id);
    },
    loaddata() {
      // console.log('user ',localStorage.getItem('user'));
      const params = {};
      api
        .get("/admin/listusers", params)
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = response.data.total;
          console.log(response.data);
        })
        .catch((error) => {
          // router.push('/pages/not-authorized');
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

